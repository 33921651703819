import { makeAutoObservable, runInAction } from 'mobx'

const KEY_USER_ID = 'KEY_USER_ID'
const KEY_WEBSITE = 'KEY_WEBSITE'

class Auth {

  constructor(root) {
    makeAutoObservable(this, { rootStore: false })
    this.root = root
    this.userId = root.getSession(KEY_USER_ID)
    this.website = root.getSession(KEY_WEBSITE)
  }

  setup(userId, website) {
    runInAction(() => {
      this.userId = userId
      this.website = website
    });
    this.root.putSession(KEY_USER_ID, userId)
    this.root.putSession(KEY_WEBSITE, website)
  }

  // HT 账号 SN
  userId = null
  // 从哪个网站打开，HTML 代码应用到哪个网站
  website = ''
}

export default Auth
