import { makeAutoObservable } from 'mobx'
import Auth from './Auth'
import Media from './Media'

class Root {
  constructor() {
    this.mediaStore = new Media(this)
    this.authStore = new Auth(this)
    makeAutoObservable(this)
  }

  clearSession() {    
    if (window.sessionStorage) {
      const sessionStorage = window.sessionStorage
      sessionStorage.clear()
    } else {
      console.error('browser not support sessionStorage!')
    }
  }

  getSession(key) {
    if (window.sessionStorage) {
      const sessionStorage = window.sessionStorage
      return sessionStorage.getItem(key)
    } else {
      console.error('browser not support sessionStorage!')
      return null
    }
  }

  putSession(key, value) {
    if (window.sessionStorage) {
      const sessionStorage = window.sessionStorage
      return sessionStorage.setItem(key, value)
    } else {
      console.error('browser not support sessionStorage!')
    }
  }

  getLocal(key) {
    if (window.localStorage) {
      const localStorage = window.localStorage
      return localStorage.getItem(key)
    } else {
      console.error('browser not support localStorage!')
      return null
    }
  }

  putLocal(key, value) {
    if (window.localStorage) {
      const localStorage = window.localStorage
      return localStorage.setItem(key, value)
    } else {
      console.error('browser not support localStorage!')
    }
  }
}

export default Root
