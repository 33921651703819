import React from 'react'
import ReactDOM from 'react-dom/client'
import { configure } from 'mobx'
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'
import RootStore from '@/stores/Root'
import { StoreContext } from '@/stores/StoreContext'
import App from '@/views/App.jsx'
import ImageUpload from '@/views/ImageUpload'
import ImageCrop from '@/views/ImageCrop'
import ImageSearch from '@/views/ImageSearch'
import Authorise from '@/views/Authorise'

configure({
  useProxies: 'ifavailable',
  enforceActions: 'observed',
  computedRequiresReaction: true,
  observableRequiresReaction: false,
  reactionRequiresObservable: true,
  disableErrorBoundaries: process.env.NODE_ENV == 'production'
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [      
      { index: true, element: <ImageSearch /> },
      { path: 'image/search', element: <ImageSearch />},
      { path: 'image/upload', element: <ImageUpload />},
      { path: 'image/crop', element: <ImageCrop />},
      { path: 'authorise/:userId/:website', element: <Authorise />},
    ]
  }
])

const rootStore = new RootStore();

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
    <StoreContext.Provider value={rootStore}>
      <RouterProvider
        router={router}
        fallbackElement={() => <div>Loading...</div>}
      />
    </StoreContext.Provider>
  // </React.StrictMode>,
)
