import { Outlet, Link, useHref, NavLink } from 'react-router-dom'
import { useRef, useEffect, useState } from 'react'
import { Layout, Menu, ConfigProvider, theme, Empty, Row, Col, Typography, Result, App as AntApp } from 'antd'
import '@/assets/App.css'
import AppLogo from '@/assets/logo-gh.png'
import { useStore } from '@/stores/StoreContext.js'
import { isEmpty } from '@/utils/commons'

const { Header, Footer, Content } = Layout
const { Title } = Typography

function App() {

  const href = useHref()
  const { authStore } = useStore()
  const { userId, website } = authStore
  const shouldBeLogin = (isEmpty(userId) || isEmpty(website)) && (href.indexOf('/authorise/') == -1)
  let defaultPath = 'search'

  if (href !== '/') {
    const splitPath = href.split('/')

    if (splitPath.length > 1) {
      defaultPath = splitPath[2]
    }
  }
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  function globalEmpty() {
    return (
      <Empty description={false} />
    )
  }

  function renderLogin() {
    return (
      <Result
        status='403'
        title='授权失败'
        subTitle='请登陆信息平台，通过指定链接打开。'
      />
    )
  }

  function renderLayout() {
    return (
      <Layout>
        <Header className='header' style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%', background: 'white' }}>
          <Row gutter={{ md: 24 }} align='middle'>
            <Col span={5}>
              <NavLink to='/'>
                <img src={AppLogo} className='logo' alt='App logo' />
              </NavLink>
              <Title level={3}>
                多媒体图片
              </Title>
            </Col>
            <Col span={10}>
              <Menu
                mode='horizontal'
                selectedKeys={[defaultPath]}
                items={[
                  { key: 'search', label: <Link to='/image/search'>搜索图片</Link> },
                  { key: 'upload', label: <Link to='/image/upload'>上传图片</Link> }
                ]}
              />
            </Col>
          </Row>
        </Header>
        <Layout>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
            }}>
            <Outlet />
          </Content>
        </Layout>
        <Footer>桂林海纳国际旅行社有限公司</Footer>
      </Layout>
    )
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#8cc269',
          borderRadius: 4
        },
        algorithm: theme.defaultAlgorithm,
      }}
      renderEmpty={globalEmpty}
    >
      <AntApp>
        {shouldBeLogin ? renderLogin() : renderLayout()}
      </AntApp>
    </ConfigProvider>
  )
}

export default App
