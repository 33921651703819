import { makeAutoObservable, runInAction } from 'mobx'
import { fetchJSON, postForm } from '@/utils/request'
import { prepareUrl, isNotEmpty, isEmpty } from '@/utils/commons'

class Media {

  constructor(root) {
    makeAutoObservable(this, { rootStore: false })
    this.root = root
  }

  fetchOptionList() {
    const fetchCountryUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSys/InfoSys/GetCountryList'
    const fetchPhotographerUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSys/InfoSys/GetphotographerList'
    const fetchTagUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSys/InfoSys/GetPhotoTagList'

    const countryPromise = fetchJSON(fetchCountryUrl)
      .then(json => {
        if (json.errcode == 0) {
          const countryOptionList = (json?.result ?? []).map((data, index) => {
            return {
              value: data.COI_SN,
              label: data.COI_Name
            }
          })
          countryOptionList.unshift({value:-1, label: '未知'})
          return countryOptionList
        } else {
          throw new Error(json.errmsg + ': ' + json.errcode)
        }
      })

    const cityPromise = this.fetchCityList('')

    const photographerPromise = fetchJSON(fetchPhotographerUrl)
      .then(json => {
        if (json.errcode == 0) {
          const photographerOptionList = (json?.result ?? []).map((data, index) => {
            return {
              value: data.U_Name,
              label: data.U_Name
            }
          })
          return photographerOptionList
        } else {
          throw new Error(json.errmsg + ': ' + json.errcode)
        }
      })

    const tagPromise = fetchJSON(fetchTagUrl)
      .then(json => {
        if (json.errcode == 0) {
          const tagOptionList = (json?.result ?? []).map((data, index) => {
            return {
              value: data.Tag_SN,
              label: data.Tag_Name
            }
          })
          return tagOptionList
        } else {
          throw new Error(json.errmsg + ': ' + json.errcode)
        }
      })
      
    return Promise.all([countryPromise, cityPromise, photographerPromise, tagPromise])
      .then(results => {
        return {
          countryOptionList: results[0],
          cityOptionList: results[1],
          photographerOptionList: results[2],
          tagOptionList: results[3]
        }
      })
  }

  getKeywordHistory() {
    const keywordListText = this.root.getLocal('KEYWORD_LIST')
    const keywordList = isEmpty(keywordListText) ? [] : JSON.parse(keywordListText)
    return keywordList.map(keyword => {
      return {value: keyword}
    })
  }

  fetchCityList(countryId) {    
    const fetchCityUrl = 
      prepareUrl('https://p9axztuwd7x8a7.mycht.cn/service-InfoSys/InfoSys/GetCityList')
        .append('coi_sn', countryId)
        .build()
    return fetchJSON(fetchCityUrl)
      .then(json => {
        if (json.errcode == 0) {
          const cityOptionList = (json?.result ?? []).map((data, index) => {
            return {
              value: data.CII_SN,
              label: data.CII_Name
            }
          })
          cityOptionList.unshift({value:-1, label: '未知'})
          return cityOptionList
        } else {
          throw new Error(json.errmsg + ': ' + json.errcode)
        }
      })
  }

  fetchCropImageList(userId) {
    const formData = new FormData()
    formData.append('PII_SN', this.selectedImage.PII_SN)
    formData.append('user_id', userId)
    const postUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSysSOA/search_cutimage'

    return postForm(postUrl, formData)
      .then(json => {
        if (json.errcode == 0) {
          runInAction(() => {
            this.croppedImageList = json.result
          })
        } else {
          throw new Error(json.errmsg + ': ' + json.errcode)
        }
      })
  }

  toggleFavorite(image, userId) {
    runInAction(() => {
      image.isFavorite = !image.isFavorite
    })
    const formData = new FormData()
    formData.append('PII_SN', image.PII_SN)
    formData.append('user_id', userId)
    formData.append('addordelete', image.isFavorite ? 1: 0)
    const postUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSysSOA/favorite_image'

    return postForm(postUrl, formData)
      .then(json => {
        if (json.errcode == 0) {
          console.info(json)
          // json.result.PII_SN
        } else {
          throw new Error(json.errmsg + ': ' + json.errcode)
        }
      })
  }

  fetchImageList(country, city, keyword, tags, star, type, userId) {
    
    runInAction(() => {
      this.imageSearchList = []
    })
    
    if (isNotEmpty(keyword)) {
      const keywordListText = this.root.getLocal('KEYWORD_LIST')
      const keywordList = isEmpty(keywordListText) ? [] : JSON.parse(keywordListText)
      if (keywordList.indexOf(keyword) == -1) {
        
        keywordList.unshift(keyword)
      }
      if (keywordList.length > 8) {
        keywordList.splice(8, keywordList.length)
      }
      this.root.putLocal('KEYWORD_LIST', JSON.stringify(keywordList))
    }

    const formData = new FormData()
    formData.append('CountrySN', country)
    formData.append('citySN', city)
    formData.append('keyword', keyword)
    formData.append('tags', tags)
    formData.append('star', star)
    formData.append('searchType', type)
    formData.append('user_id', userId)
    const postUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSysSOA/search_image'

    return postForm(postUrl, formData)
      .then(json => {
        if (json.errcode == 0) {
          runInAction(() => {
            this.imageSearchList = json.result
          })
        } else {
          throw new Error(json.errmsg + ': ' + json.errcode)
        }
      })
  }

  startUpload(userId) {

    for (let index = 0; index < this.imageUploadList.length; index++) {
      const element = this.imageUploadList[index];      
      this.requiredAlert = 
        (element.country === null) ||
        (element.city === null) ||
        (element.description_zh === '') ||
        (element.description_en === '') ||
        (element.photographer === '') ||
        (element.copyright === -1) ||
        (element.star === -1)
      
      if (this.requiredAlert) {
        console.error(element)
        break
      }
    }

    if (this.requiredAlert) return

    let successCount = 0
    this.uploadPercent = 0
    const imageCount = this.imageUploadList.length      
    const postUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSysSOA/upload_image'

    this.imageUploadList.forEach((element, index) => {
      const formData = new FormData()
      formData.append('image_file', element.image_file)
      formData.append('image_uid', element.image_uid)
      formData.append('country', element.country)
      formData.append('city', element.city)
      formData.append('description_zh', element.description_zh)
      formData.append('description_en', element.description_en)
      formData.append('photographer', element.photographer)
      formData.append('copyright', element.copyright)
      formData.append('labels', element.labelValues.join(','))
      formData.append('user_id', userId)
      formData.append('star', element.star)

      return postForm(postUrl, formData)
        .then(json => {
          successCount++          
          runInAction(() => {
            this.uploadPercent = parseInt(successCount / imageCount * 100)
          })
          if (json.errcode == 0) {
            runInAction(() => {
              for (var i = this.imageUploadList.length - 1; i >= 0; i--) {
                const current = this.imageUploadList[i]
                if (current.image_uid === json.result.image_uid) {
                  current.success = true
                  current.image_url = 'https://p9axztuwd7x8a7.mycht.cn/service-fileServer' + json.result.image_path
                  break
                }
              }
            })
          } else {
            throw new Error(json.errmsg + ': ' + json.errcode)
          }
        })
    })
  }

  startCrop(cropData, resizeWidth, userId, website) {
    const formData = new FormData()
    formData.append('filename', this.selectedImage.PII_Location + this.selectedImage.PII_FileName)
    formData.append('x', cropData.x)
    formData.append('y', cropData.y)
    formData.append('width', Math.trunc(cropData.width))
    formData.append('height', Math.trunc(cropData.height))
    formData.append('resize_width', resizeWidth)
    formData.append('user_id', userId)
    formData.append('webcode', website)
    const postUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSysSOA/crop_image'

    return postForm(postUrl, formData)
      .then(json => {
        if (json.errcode == 0) {
          this.fetchCropImageList()
          const imageHtml = this.generateHtml(json.result, website)
          const cropResult = {
            imageHtml: imageHtml
          }
          return cropResult
        } else {
          throw new Error(json.errmsg + ': ' + json.errcode)
        }
      })
  }

  getImageHost(website) {
    let imageHost = ''
    switch (website) {
      case 'ch':
        imageHost = 'https://images.chinahighlights.com'
        break
      case 'ah':
        imageHost = 'https://images.asiahighlights.com'
        break
      case 'gh':
        imageHost = 'https://images.globalhighlights.com'
        break
      case 'chinatravel':
        imageHost = 'https://images.chinatravel.com'
        break
      case 'sht':
        imageHost = 'https://images.shanghaihighlights.com'
        break
      case 'ts':
        imageHost = 'https://images.trainspread.com'
        break
      case 'mbj':
        imageHost = 'https://images.mybeijingchina.com'
        break
      default:
        imageHost = 'https://images.chinahighlights.com'
        break
    }
    return imageHost
  }

  generateHtml(imageObj, website) {
    const imageUrl = this.getImageHost(website) + imageObj.PII_Location + imageObj.PII_FileName
    let imageHtml = ''
    
    switch (website) {
      case 'ch':
        imageHtml = '<div class="infoimage"><img alt="' + imageObj.PII2_Intro + '" class="img-responsive" '+ ' width="' + imageObj.PII_Width + '" height="' + imageObj.PII_Height + '" src="' + imageUrl + '"><span class="infoimagetitle">' + imageObj.PII2_Intro + '</span></div>'
        break
      case 'ah':
        imageHtml = '<div class="infoimage"><img alt="' + imageObj.PII2_Intro + '" class="img-responsive" '+ ' width="' + imageObj.PII_Width + '" height="' + imageObj.PII_Height + '" src="' + imageUrl + '"><span class="photoTxt">' + imageObj.PII2_Intro + '</span></div>'
        break
      case 'gh':
        imageHtml = '<div class="infoimage"><img alt="' + imageObj.PII2_Intro + '" class="img-responsive" '+ ' width="' + imageObj.PII_Width + '" height="' + imageObj.PII_Height + '" src="' + imageUrl + '"><span class="infoimagetitle">' + imageObj.PII2_Intro + '</span></div>'
        break
      case 'chinatravel':
      case 'sht':
      case 'ts':
      case 'mbj':
        imageHtml = '<figure><img alt="' + imageObj.PII2_Intro + '" class="img-responsive" '+ ' width="' + imageObj.PII_Width + '" height="' + imageObj.PII_Height + '" src="' + imageUrl + '"></figure>'
        break
      default:
        imageHtml = '<img alt="' + imageObj.PII2_Intro + '" class="img-responsive" '+ ' width="' + imageObj.PII_Width + '" height="' + imageObj.PII_Height + '" src="' + imageUrl + '">'
        break
    }

    return imageHtml
  }

  useImage(imageId, userId, website) {
    const formData = new FormData()
    formData.append('PII_SN', imageId)
    formData.append('user_id', userId)
    formData.append('Webcode', website)
    const postUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSysSOA/use_image'

    return postForm(postUrl, formData)
      .then(json => {
        if (json.errcode == 0) {
          console.info(json)
        } else {
          throw new Error(json.errmsg + ': ' + json.errcode)
        }
      })
  }

  continueUpload() {
    runInAction(() => {
      this.imageUploadList = []
      this.uploadPercent = -1
    })
  }

  initImageList(fileList) {
    runInAction(() => {
      this.imageUploadList = fileList.map(file => {
        return {
          image_file: file,
          image_uid: file.uid,
          country: null,
          city: null,
          description_zh: '',
          description_en: '',
          photographer: '',
          copyright: -1,
          labels: '',
          labelValues: [],
          user_id: -1,
          star: -1,
          success: false,
          image_url: ''
        }
      })
    })
  }

  removeUploadImage(fileUid) {
    runInAction(() => {
      this.imageUploadList = this.imageUploadList.filter((image, index) => {
        return image.image_uid != fileUid
      })
    })
  }

  syncProperties() {
    if (this.imageUploadList.length > 1) {
      const source = this.imageUploadList[0]
      runInAction(() => {
        for (let index = 1; index < this.imageUploadList.length; index++) {
          const current = this.imageUploadList[index]
          current.country = source.country
          current.city = source.city
          current.description_zh = source.description_zh
          current.description_en = source.description_en
          current.photographer = source.photographer
          current.copyright = source.copyright
          current.labelValues = source.labelValues
          current.user_id = source.user_id
          current.star = source.star
        }
      })
    }
  }

  updateImageProperty(uid, name, value) {
    for (var i = this.imageUploadList.length - 1; i >= 0; i--) {
      const current = this.imageUploadList[i]
      if (current.image_file.uid === uid) {
        current[name] = value
        break
      }
    }
  }

  selectImage(image) {
    this.selectedImage = image
  }

  imageUploadList = []
  imageSearchList = []
  uploadPercent = -1
  selectedImage = null
  croppedImageList = []
  // 上传时数据验证是否通过
  requiredAlert = false
}

export default Media
