import { useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"

import '@/assets/cropper.css'
import { useStore } from '@/stores/StoreContext.js'

function Authorise() {

  const navigate = useNavigate()
  const { userId, website } = useParams()  
  const { authStore } = useStore()
  authStore.setup(userId, website)
  useEffect(() => {
    navigate('/')
  }, [])
}

export default Authorise