import { useNavigate } from 'react-router-dom'
import { useRef, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Divider, Checkbox, Card, Button, Input, 
  Space, Empty, Radio, Select, AutoComplete, Spin, Typography, Flex
} from 'antd'
import {
  StarFilled, ZoomInOutlined, StarOutlined, SearchOutlined
} from '@ant-design/icons'

import { useStore } from '@/stores/StoreContext.js'
import { splitArray2Parts } from '@/utils/commons'

function ImageSearch() {
  const navigate = useNavigate()
  const keywordRef = useRef()
  const imageSearchRef = useRef()
  const { mediaStore, authStore } = useStore()
  const { imageSearchList } = mediaStore
  const [tagSelectOptions, setTagOptions] = useState([])
  const [selectedStarList, setStarList] = useState([])
  const [selectedTagList, setTagList] = useState([])
  const [selectedType, setSearchType] = useState('library')
  const [selectedCountry, setCountry] = useState('')
  const [selectedCity, setCity] = useState('')
  const [inputKeyword, setKeyword] = useState('')
  const [serachLoading, setSearchLoading] = useState(false)
  const [checkAllStar, setCheckAllStar] = useState(false)
  const [countrySelectOptions, setCountrySelectOptions] = useState([])
  const [citySelectOptions, setCitySelectOptions] = useState([])
  const [keywordHistoryOptions, setkeywordHistoryOptions] = useState([])
  const [renderLength, setRenderLength] = useState(4)

  const imageSplittedList = splitArray2Parts(imageSearchList, 4)
  
  useEffect(() => {
    setkeywordHistoryOptions(mediaStore.getKeywordHistory())
    mediaStore.fetchOptionList()
    .then((result) => {
      setCountrySelectOptions(result.countryOptionList)
      setCitySelectOptions(result.cityOptionList)
      setTagOptions(result.tagOptionList)
    })
  }, [])
  
  useEffect(() => {
    let timer = null;
    const viewClintHeight = document.documentElement.clientHeight;
    const scrollCallback = (e) => {
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        const obj = imageSearchRef.current.getBoundingClientRect()
        if (obj.bottom <= viewClintHeight) setRenderLength(renderLength + 4)
      }, 100)
    }
    window.addEventListener('scroll', scrollCallback)

    return () => {
      window.removeEventListener('scroll', scrollCallback)
    }
  }, [imageSearchList, renderLength])

  function search() {
    setSearchLoading(true)
    mediaStore.fetchImageList(
      selectedCountry, 
      selectedCity, 
      inputKeyword, 
      selectedTagList.join(','), 
      selectedStarList.join(','), 
      selectedType, 
      authStore.userId)
      .finally(() => {
        setSearchLoading(false)
        setkeywordHistoryOptions(mediaStore.getKeywordHistory())
      })
  }

  function imageColumnRender(imageColumn, index) {
    const originNameArr = imageColumn.PII_FileName.split('.')
    const thumbnailUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-fileServer' + imageColumn.PII_Location + originNameArr[0] + '-m.' + originNameArr[1]
    const originUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-fileServer' + imageColumn.PII_Location + imageColumn.PII_FileName
    const countryName = imageColumn.PII_CountryCN || '未知'
    const cityName = imageColumn.CII_Name || '未知'
    const imageSize = imageColumn.PII_Width + '*' + imageColumn.PII_Height
    return (
      <Col span={6} key={index}>
        <Card
          hoverable
          style={{
            width: '100%',
          }}
          bodyStyle={{
            padding: 0,
            overflow: 'hidden',
          }}
        >
          <Flex justify='space-between' vertical={true}>
            <img
              style={{ cursor: 'pointer', width: '100%', height: 200, objectFit: 'scale-down', objectPosition: 'center' }}
              alt={imageColumn.PII2_Name}
              onClick={() => { 
                mediaStore.selectImage(imageColumn)
                navigate('/image/crop') 
              }}
              src={thumbnailUrl}
              lazy="true"
            />
            <Flex
              vertical
              align='flex-start'
              justify='flex-start'
              style={{
                padding: 16,
              }}
            >
              <Typography.Text strong={true} ellipsis={{
                tooltip: imageColumn.PII_ShortIntro,
              }} style={{fontSize: 16}}>{imageColumn.PII_ShortIntro}</Typography.Text>
              <Typography.Text strong={true} ellipsis={{
                tooltip: imageColumn.PII2_Intro,
              }} style={{fontSize: 16}}>{imageColumn.PII2_Intro}</Typography.Text>
              <Typography.Text>{countryName + ', ' + cityName}</Typography.Text>
              <Typography.Text>{'尺寸：' + imageSize}</Typography.Text>
              <Flex
                vertical={false}
                justify='center'
                align='center'
                style={{
                  padding: 0,
                  width: '100%'
                }}
              >
                <Button type='text' block icon={<ZoomInOutlined />} target='_blank' href={originUrl} />
                <Divider type='vertical' />
                <Button type='text' block style={imageColumn.isFavorite ? {color: '#8cc269'} : {}} icon={imageColumn.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => { 
                  mediaStore.toggleFavorite(imageColumn, authStore.userId)
                }} />
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Col>
    )
  }

  function imageRowRender(imageRow, index) {
    return (
      <Row gutter={[16, 16]} key={index} align='middle'>
        {imageRow.map(imageColumnRender)}
      </Row>
    )
  }

  return (
    <Spin spinning={serachLoading} delay={500}>
      <Space direction='vertical' style={{ width: '100%' }}>
        <Row gutter={[16, 16]} justify='start' align='middle'>
          <Col span={24}>            
            <Radio.Group
              options={[
                { label: '所有图片', value: 'library' },
                { label: '我用过的', value: 'myused' },
                { label: '我上传的', value: 'myupload' },
                { label: '我收藏的', value: 'myfavorites' },
                { label: '未审核', value: 'unCheck' }
              ]}
              value={selectedType}
              onChange={({ target: { value } }) => {              
                setSearchType(value)
              }}
              optionType='button'
              buttonStyle='solid'
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify='start' align='middle'>
        <Col span={4}>
            <Select
              style={{ width: '100%' }}
              showSearch
              value={[selectedCountry]}
              placeholder='国家'
              onChange={(value) => {
                setCountry(value)
                mediaStore.fetchCityList(value)
                  .then((result) => {
                    setCitySelectOptions(result)
                  })
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={countrySelectOptions}
            />
          </Col>
          <Col span={4}>
            <Select
              style={{ width: '100%' }}
              placeholder='城市'
              value={[selectedCity]}
              showSearch
              onChange={(value) => {
                setCity(value)
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={citySelectOptions}
            />
          </Col>
          <Col span={5}>
            <AutoComplete
              style={{ width: '100%' }}
              options={keywordHistoryOptions}
              value={inputKeyword}
              placeholder='图片名，多个关键词之间使用空格分开'
              onChange={(value) => {
                setKeyword(value)
              }}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Col>
          </Row>
          <Row justify='middle'>
          <Col span={5}>
            <Checkbox.Group 
              value={selectedStarList}
              options={[
                { label: '五星', value: '5' },
                { label: '四星', value: '4' },
                { label: '三星', value: '3' }
              ]}
              onChange={(list) => {
                setStarList(list)
              }}
            />
            <Checkbox indeterminate={false} onChange={(e) => {
              setCheckAllStar(e.target.checked)
              setStarList(e.target.checked ? ['3', '4', '5'] : []);
            }} checked={checkAllStar}>全部</Checkbox>
          </Col>
          <Col span={4}>
            <Checkbox.Group options={tagSelectOptions}
              onChange={(list) => {
                setTagList(list)
              }}
            />
          </Col>
          <Col span={2}>
            <Button icon={<SearchOutlined />} onClick={() => {
              search()
            }}>搜索</Button>
          </Col>
        </Row>
      </Space>
      <Divider plain orientation='left'>图片列表(共 {imageSearchList.length} 张)</Divider>
      <Space
        ref={imageSearchRef}
        direction='vertical'
        size='middle'
        style={{
          display: 'flex',
        }}
      >
        {imageSplittedList.length > 0 ? imageSplittedList.slice(0, renderLength).map(imageRowRender) : <Empty description={false} />}
      </Space>
    </Spin>
  )
}

export default observer(ImageSearch)
