function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    const message =
      'Fetch error: ' + response.url + ' ' + response.status + ' (' +
      response.statusText + ')'
    const error = new Error(message)
    error.response = response
    throw error
  }
}

export function fetchText(url) {
  return fetch(url)
    .then(checkStatus)
    .then(response => response.text())
    .catch(error => {
      throw error
    })
}

export function fetchJSON(url) {
  return fetch(url)
    .then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      throw error
    })
}

export function postForm(url, data) {
  return fetch(url, {
    method: 'POST',
    body: data
  }).then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      throw error
    })
}

export function postJSON(url, obj) {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(obj),
    headers: {
      'Content-type': 'application/json; charset=UTF-8'
    }
  }).then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      throw error
    })
}

export function postStream(url, obj) {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(obj),
    headers: {
      'Content-type': 'application/octet-stream'
    }
  }).then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      throw error
    })
}
